import { useApiClient } from './apiClient';

export const useCardService = () => {
  const apiClient = useApiClient();
  
  return {
    getCards: () => apiClient.get('api/cards'),
    createCard: (newCard) => apiClient.post('api/cards', JSON.stringify(newCard)),
    updateCard: (id, card) => {return apiClient.put(`api/cards/${id}`, JSON.stringify(card));},
    deleteCard: (CardId) => apiClient.delete(`api/cards/${CardId}`)
  };
};