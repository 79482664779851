import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCategoryService } from "../../services/categoryService";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import CategoryIcon from "@mui/icons-material/Category";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import CustomizableHeader from "../../components/common/CustomizeableHeader";
import ActionButton from "../../components/common/ActionButton";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import CustomStyledTextField from "../../components/common/CustomStyledTextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMessage } from "../../contexts/MessageContext";

const AdminCategories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [showNewCategoryForm, setShowNewCategoryForm] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const theme = useTheme();
  const { showMessage } = useMessage();
  const categoryService = useCategoryService();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await categoryService.getCategories();
      setCategories(response.data.categories);
    } catch (err) {
      showMessage("Fehler beim Laden der Kategorien.", "error");
    } finally {
      setLoading(false);
    }
  };

  const createCategory = async (e) => {
    e.preventDefault();
    if (!newCategory.trim()) return;
    setLoading(true);
    try {
      await categoryService.createCategory({ name: newCategory });
      showMessage("Kategorie erfolgreich erstellt.", "success");
      setNewCategory("");
      setShowNewCategoryForm(false);
      fetchCategories();
    } catch (err) {
      showMessage("Fehler beim Erstellen der Kategorie.", "error");
    } finally {
      setLoading(false);
    }
  };

  const startEditingCategory = (category) => {
    setEditingCategory(category);
    setEditedCategoryName(category.category_name);
  };

  const cancelEditingCategory = () => {
    setEditingCategory(null);
    setEditedCategoryName("");
  };

  const saveEditedCategory = async (e) => {
    e.preventDefault();
    if (!editedCategoryName.trim() || !editingCategory) return;
    setLoading(true);
    try {
      await categoryService.updateCategory(editingCategory.category_id, {
        name: editedCategoryName,
      });
      showMessage("Kategorie erfolgreich aktualisiert.", "success");
      setEditingCategory(null);
      setEditedCategoryName("");
      fetchCategories();
    } catch (err) {
      showMessage("Fehler beim Aktualisieren der Kategorie.", "error");
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (categoryId) => {
    setCategoryToDelete(categoryId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setCategoryToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const confirmDeleteCategory = async () => {
    if (!categoryToDelete) return;
    setLoading(true);
    try {
      await categoryService.deleteCategory(categoryToDelete);
      showMessage("Kategorie erfolgreich gelöscht.", "success");
      fetchCategories();
    } catch (err) {
      showMessage("Fehler beim Löschen der Kategorie.", "error");
    } finally {
      setLoading(false);
      closeDeleteModal();
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizableHeader
        leftIcon={<CategoryIcon />}
        leftText="Admin-Kategorien"
        rightContent={
          <Link to="/admin" style={{ color: "inherit" }}>
            <IconButton>
              <AdminIcon />
            </IconButton>
          </Link>
        }
      />
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          p: theme.spacing(3),
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          m: theme.spacing(2),
          boxShadow: theme.shadows[1],
        }}
      >
        <ActionButton
          sx={{
            mt: theme.spacing(3),
          }}
          fullWidth
          action={showNewCategoryForm ? "cancel" : "create"}
          onClick={() => setShowNewCategoryForm(!showNewCategoryForm)}
        >
          {showNewCategoryForm ? "Abbrechen" : "Neue Kategorie erstellen"}
        </ActionButton>
        {showNewCategoryForm && (
          <Box mt={2}>
            <form
              onSubmit={createCategory}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <CustomStyledTextField
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                label="Name der Kategorie"
                required
              />
              <ActionButton
                action="save"
                type="submit"
                variant="create"
                sx={{ mt: theme.spacing(2) }}
              >
                Speichern
              </ActionButton>
            </form>
          </Box>
        )}
        {categories.map((category) => (
          <React.Fragment key={category.category_id}>
            <Divider sx={{ my: theme.spacing(2) }} />
            {editingCategory?.category_id === category.category_id ? (
              <Box>
                <form onSubmit={saveEditedCategory} style={{ display: "flex", flexDirection: "column" }}>
                  <CustomStyledTextField
                    value={editedCategoryName}
                    onChange={(e) => setEditedCategoryName(e.target.value)}
                    label="Kategorie bearbeiten"
                    required
                  />
                  <Box sx={{ display: "flex", justifyContent: "space-between", mt: theme.spacing(2) }}>
                    <ActionButton action="cancel" onClick={cancelEditingCategory}>
                      Abbrechen
                    </ActionButton>
                    <ActionButton action="save" type="submit">
                      Speichern
                    </ActionButton>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box>
                <Typography variant="h6">{category.category_name}</Typography>
                <Typography variant="body1">ID: {category.category_id}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-around", marginTop: theme.spacing(1) }}>
                  <ActionButton action="edit" onClick={() => startEditingCategory(category)} />
                  <ActionButton action="delete" onClick={() => openDeleteModal(category.category_id)} />
                </Box>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDeleteCategory}
        title="Kategorie löschen?"
        message="Möchten Sie diese Kategorie wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
        confirmText="Ja, löschen"
        cancelText="Abbrechen"
      />
    </>
  );
};

export default AdminCategories;

