import React from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import ActionButton from '../common/ActionButton';
import CustomizableHeader from '../common/CustomizeableHeader';
import InfoHint from '../common/InfoHint';
import FullCardItem from '../cards/FullCardItem';

const AvailableCardsModal = ({ open, onClose, availableCards }) => {
  const theme = useTheme();

  if (!open) return null;

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 60,
          width: '100vw',
          height: '100vh',
          backgroundColor: theme.palette.background.default,
          overflowY: 'auto',
        }}
      >
        <CustomizableHeader
          leftIcon={null}
          leftText="Verfügbare Karten"
          rightContent={<ActionButton action="cancel" onClick={onClose}>Zurück</ActionButton>}
          sx={{ position: 'absolute', top: 0, backgroundColor: theme.palette.background.paper }}
        />

        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            {availableCards.length > 0 ? (
              availableCards.map((card) => (
                <Grid item xs={12} key={card.card_id}>
                  <FullCardItem card={card} />
                </Grid>
              ))
            ) : (
              <InfoHint message='Keine Karten mehr verfügbar.' />
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AvailableCardsModal;
