import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Divider,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useMessage } from '../contexts/MessageContext';
import { useNewsService } from '../services/newsService';
import NewsContent from '../components/common/NewsContent';
import CustomizableHeader from '../components/common/CustomizeableHeader';
import ActionButton from '../components/common/ActionButton';
import Logo from '../components/common/Logo';

const Home = () => {
  const theme = useTheme();
  const { user, isLoading } = useAuth();
  const { showMessage } = useMessage();
  const [news, setNews] = useState([]);
  const newsService = useNewsService();

  useEffect(() => {
    const loginSuccess = sessionStorage.getItem('loginSuccess');
    if (loginSuccess === 'true') {
      showMessage('Erfolgreich angemeldet!');
      sessionStorage.removeItem('loginSuccess');
    }
  }, [showMessage]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await newsService.getNews();
        setNews(response.data.news);
      } catch (error) {
        showMessage('Fehler beim Laden der Neuigkeiten', 'error');
        console.error('Error fetching news:', error);
      }
    };
    fetchNews();
  }, [showMessage]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: theme.spacing(4) }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizableHeader
        leftIcon={ <Logo src="/logo192.png" alt="App Logo" />}
        leftText="Facettenschach"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(3) }}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            p: theme.spacing(2),
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          <Typography variant="h5">Willkommen 🎉</Typography>
          <Typography align="left" sx={{ mb: theme.spacing(2) }}>
            Entdecke eine neue Dimension des Schachs! Baue dein Kartendeck mit
            spannenden Effekten und revolutioniere dein Spiel. Neugierig?{' '}
            <Link to="/anleitung" style={{ textDecoration: 'none' }}>
              <Typography
                component="span"
                color="primary"
                sx={{ textDecoration: 'underline' }}
              >
                Zur Anleitung
              </Typography>
            </Link>
          </Typography>
          {!user && (
            <>
              <Divider sx={{ my: theme.spacing(2) }} />
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <ActionButton 
                  action="submit" 
                  color="primary" 
                  component={Link} 
                  to="/anmelden"
                >
                  Anmelden
                </ActionButton>
                <ActionButton component={Link} to="/registrieren">
                  Registrieren
                </ActionButton>
              </Box>
            </>
          )}
          {news.length > 0 && (
            <>
              <Divider sx={{ my: theme.spacing(2) }} />
              <Typography variant="h5">Neuigkeiten 📰</Typography>
              <NewsContent news={news} showToggleButton={false} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Home;