import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Switch,
  Divider,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PeopleIcon from "@mui/icons-material/People";
import { useUserService } from "../../services/userService";
import CustomizableHeader from "../../components/common/CustomizeableHeader";
import { useMessage } from "../../contexts/MessageContext";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const userService = useUserService();
  const { showMessage } = useMessage();
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await userService.getUsers();
      setUsers(response.data.users);
    } catch (error) {
      showMessage(
        "Error fetching users: " +
          (error.response?.data?.message || error.message),
        "error"
      );
    }
  };

  const handleToggleAdmin = async (id) => {
    try {
      await userService.toggleAdminStatus(id);
      showMessage("Admin status erfolgreich geändert.", "success");
      fetchUsers();
    } catch (error) {
      showMessage(
        "Error toggling admin status: " +
          (error.response?.data?.message || error.message),
        "error"
      );
    }
  };

  return (
    <Box>
      <CustomizableHeader
        leftIcon={<PeopleIcon />}
        leftText="Verwaltung"
        rightContent={
          <Link to="/admin">
            <IconButton>
              <AdminPanelSettingsIcon />
            </IconButton>
          </Link>
        }
      />

      <Box
        sx={{
          flexGrow: 1,
          padding: 3,
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          bgcolor: "background.paper",
          boxShadow: 1,
          margin: 3,
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Admin-Status verwalten:
        </Typography>

        {users.map((user, index) => (
          <React.Fragment key={user.user_id}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {user.user_name}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Switch
                  checked={user.is_admin}
                  onChange={() => handleToggleAdmin(user.user_id)}
                  color="primary"
                />
              </Grid>
            </Grid>
            {index < users.length - 1 && <Divider sx={{ my: 2 }} />}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default AdminUsers;
