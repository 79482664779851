import { useApiClient } from './apiClient';

export const useAuthService = () => {
  const apiClient = useApiClient();

  return {
    login: (user_name, password) => 
      apiClient.post('auth/login', { user_name, password }),
    
    register: (user_name, password) => 
      apiClient.post('auth/register', { user_name, password }),
    
    logout: () =>
      apiClient.post('auth/logout'),

    checkAuth: () =>
      apiClient.get('auth/check'),
  };
};