import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LoginIcon from '@mui/icons-material/Login';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp';

const StyledButton = styled(Button)(({ theme, $buttonColor }) => ({
  padding: theme.spacing(0.5, 1),
  textTransform: 'none',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  border: '1px solid',
  borderColor: getIconColor($buttonColor, theme),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderColor: getIconColor($buttonColor, theme),
  },
  '& .MuiButton-startIcon': {
    marginRight: 2,
  },
  '& .MuiButton-endIcon': {
    marginLeft: 2,
  }
}));

const ColoredIcon = styled('span')(({ theme, color }) => ({
  display: 'inherit',
  '& .MuiSvgIcon-root': {
    color: getIconColor(color, theme),
    fontSize: 20,
  }
}));

const getIconColor = (color, theme) => {
  const colors = {
    error: '#ff4444',
    primary: '#1976d2',
    success: '#2e7d32',
    start: '#2e7d32',
    back: '#757575',
    filter: '#9c27b0',
  };
  return colors[color] || theme.palette.text.primary;
};

// Define which actions should have icons on the right
const rightIconActions = ['next', 'cancel'];

const ActionButton = ({ action, onClick, color, children, ...props }) => {
  const icons = {
    edit: <EditIcon />,
    delete: <DeleteIcon />,
    save: <SaveIcon />,
    cancel: <CancelIcon />,
    create: <AddBoxIcon />,
    submit: <LoginIcon />,
    start: <PlayArrowIcon />,
    back: <ArrowBackIosIcon />,
    next: <ArrowForwardIosIcon />,
    filter: <FilterAltSharpIcon />,
  };

  const translations = {
    edit: 'Bearbeiten',
    delete: 'Löschen',
    save: 'Speichern',
    cancel: 'Abbrechen',
    create: 'Erstellen',
    submit: 'Anmelden',
    start: 'Start',
    back: 'Zurück',
    next: 'Weiter',
    filter: 'Filter',
  };

  const defaultColors = {
    edit: 'primary',
    delete: 'error',
    save: 'success',
    cancel: 'error',
    create: 'primary',
    submit: 'primary',
    start: 'start',
    back: 'back',
    next: 'next',
    filter: 'filter',
  };

  const buttonColor = color || (action ? defaultColors[action] : 'primary');
  const icon = action ? (
    <ColoredIcon color={buttonColor}>
      {icons[action]}
    </ColoredIcon>
  ) : null;
  const text = children || (action ? translations[action] : '');

  // Determine if icon should be on the right
  const isRightIcon = rightIconActions.includes(action);

  return (
    <StyledButton
      startIcon={!isRightIcon ? icon : null}
      endIcon={isRightIcon ? icon : null}
      onClick={onClick}
      $buttonColor={buttonColor}
      {...props}
    >
      {text}
    </StyledButton>
  );
};

export default ActionButton;