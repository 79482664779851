import React from 'react';
import {
  Box,
  Typography,
  Button,
  Stack,
  Divider,
  Link as MuiLink,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import CustomizableHeader from '../../components/common/CustomizeableHeader';
import { Info as InfoIcon } from '@mui/icons-material';

const About = () => {
  const theme = useTheme();

  return (
    <>
      <CustomizableHeader
        leftIcon={<InfoIcon />}
        leftText="Über uns"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(3) }}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            p: theme.spacing(2),
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          <Typography variant="h5">Was ist Facettenschach?</Typography>
          <Typography align="left" sx={{ mb: theme.spacing(2) }}>
            Facettenschach ist eine innovative Schachvariante, die durch den
            Einbau von Spezialzügen und Effekten eine neue Spielerfahrung
            bietet.
          </Typography>

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Über diese Plattform</Typography>
          <Typography align="left" sx={{ mb: theme.spacing(2) }}>
            Diese Online-Plattform wurde entwickelt, um persönliche Decks zu
            erstellen und zu verwalten. Anschließend kann damit gespielt werden.
          </Typography>

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Erfinder des Spiels</Typography>
          <Typography align="left" sx={{ mb: theme.spacing(2) }}>
            Idee und Konzeption von Felix Gappel.
          </Typography>

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Technische Entwicklung</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              mb: theme.spacing(2),
            }}
          >
            <Stack direction="row" spacing={2}>
              <Link
                to="/external-link"
                state={{ url: 'https://github.com/py-bay' }}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outlined"
                  startIcon={<GitHubIcon />}
                  sx={{ textTransform: 'none' }}
                >
                  py-bay
                </Button>
              </Link>
              <Link
                to="/external-link"
                state={{ url: 'https://github.com/ellith' }}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="outlined"
                  startIcon={<GitHubIcon />}
                  sx={{ textTransform: 'none' }}
                >
                  Ellith
                </Button>
              </Link>
            </Stack>
          </Box>

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Kontakt</Typography>
          <Typography align="left">
            Für Fragen, Anregungen oder Feedback kontaktieren Sie uns bitte
            unter:{' '}
            <MuiLink href="mailto:flashflexx965@gmail.com" style={{ color: theme.palette.primary.main }}>
              chesscraft@web.de
            </MuiLink>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default About;
