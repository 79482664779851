import { useApiClient } from './apiClient';

export const useDeckService = () => {
  const apiClient = useApiClient();
 
  return {
    getDecks: () => apiClient.get('/api/decks'),
    getPublicDecks: () => apiClient.get('/api/decks/public'),
    getDeck: (deckId) => apiClient.get(`/api/deck/${deckId}`),
    createDeck: (cardIds, deck_name, isPublic = false, description = '') => 
      apiClient.post('/api/deck', { 
        card_ids: cardIds, 
        deck_name: deck_name,
        is_public: isPublic,
        description: description
      }),
    updateDeck: (deckId, cardIds, deck_name, isPublic = false, description = '') => 
      apiClient.put(`/api/deck/${deckId}`, { 
        card_ids: cardIds, 
        deck_name: deck_name,
        is_public: isPublic,
        description: description
      }),
    deleteDeck: (deckId) => apiClient.delete(`/api/deck/${deckId}`),
  };
};