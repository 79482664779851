import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import {
  RecentActors as DecksIcon,
} from '@mui/icons-material';

import CustomizableHeader from "../../components/common/CustomizeableHeader";
import LoginPrompt from "../../components/decks/LoginPromt";
import FullCardItem from "../../components/cards/FullCardItem";
import { useCardService } from "../../services/cardService";
import { useDeckService } from "../../services/deckService";
import { useAuth } from "../../contexts/AuthContext";
import CustomDialog from "../../components/common/CustomDialog";
import ActionButton from "../../components/common/ActionButton";
import CustomStyledTextField from "../../components/common/CustomStyledTextField";
import { useMessage } from "../../contexts/MessageContext";

const Deckbuilding = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const cardService = useCardService();
  const deckService = useDeckService();
  const { user } = useAuth();
  const [cardData, setCardData] = useState([]);
  const [filteredCardData, setFilteredCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCards, setSelectedCards] = useState({});
  const [selectedCardCount, setSelectedCardCount] = useState(0);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deckName, setDeckName] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editDeckId, setEditDeckId] = useState(null);
  const { showMessage } = useMessage();
  const [deckDescription, setDeckDescription] = useState('');
  const [isAdminDeck, setIsAdminDeck] = useState(false);

useEffect(() => {
  const fetchCardData = async () => {
    try {
      const response = await cardService.getCards();
      const cards = response.data.cards;
      setCardData(cards);
      setFilteredCardData(cards);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching card data:', error);
      setError(error.message);
      setLoading(false);
    }
  };
  fetchCardData();

  // Check if state was passed during navigation
  const editDeck = location.state?.deck || location.state?.editDeck;
  const isAdmin = location.state?.isAdmin || false;
  
  // Set admin status
  setIsAdminDeck(isAdmin);

  // Check if we're in edit mode
  if (editDeck) {
    setEditMode(true);
    // Use deck_id or id depending on your backend model
    setEditDeckId(editDeck.deck_id || editDeck.deck_id);
    setDeckName(editDeck.deck_name);
    
    // Set description if available
    setDeckDescription(editDeck.description || '');
    
    const selectedCardsObj = editDeck.cards.reduce((acc, card) => {
      acc[card.card_id] = true;
      return acc;
    }, {});
    
    setSelectedCards(selectedCardsObj);
    setSelectedCardCount(editDeck.cards.length);
  }
}, [location.state]);

  const handleFinalSave = async () => {
    const selectedCardIds = Object.keys(selectedCards).filter(
      (id) => selectedCards[id]
    );
  
    try {
      let response;
      if (editMode) {
        response = await deckService.updateDeck(
          editDeckId,
          selectedCardIds,
          deckName,
          isAdminDeck, // is_public flag
          deckDescription
        );
      } else {
        response = await deckService.createDeck(
          selectedCardIds, 
          deckName, 
          isAdminDeck, // is_public flag
          deckDescription
        );
      }
      
      const redirectPath = isAdminDeck ? '/admin/decks' : '/user-decks';
      // showMessage(
      //   isAdminDeck 
      //     ? "Admin-Deck erfolgreich gespeichert" 
      //     : "Deck erfolgreich gespeichert", 
      //   'success'
      // );
      console.log('Saving deck, redirecting to:', redirectPath);
      console.log('Edit Mode:', editMode, 'Redirect Path:', redirectPath);
      console.log('adminstate:', isAdminDeck)
      navigate(redirectPath);
    } catch (error) {
      console.log(error.message)
      showMessage(
        isAdminDeck
          ? "Admin-Deck konnte nicht gespeichert werden."
          : "Deck konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.",
        'error'
      );
    }
  };

  const handleFilterDialogOpen = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleCardSelection = (cardId, e) => {
    e.stopPropagation();
    setSelectedCards((prevSelected) => {
      const newSelected = {
        ...prevSelected,
        [cardId]: !prevSelected[cardId],
      };
      const newCount = Object.values(newSelected).filter(Boolean).length;
      setSelectedCardCount(newCount);
      return newSelected;
    });
  };

  const CardCounterContent = () => (
    <Box
      sx={{
        position: 'fixed',
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        border: `1px solid ${selectedCardCount === 20 
          ? theme.palette.success.main 
          : theme.palette.error.main}`,
      }}
    >
      <Typography
        sx={{
          color: selectedCardCount === 20
            ? theme.palette.success.main
            : theme.palette.error.main,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
        }}
      >
        {selectedCardCount}/20 Karten
      </Typography>
    </Box>
  );

  const renderCards = (cards) => (
    <Grid container spacing={2}>
      {cards.map((card, index) => (
        <Grid item xs={12} key={index}>
          <FullCardItem
            card={card}
            handleCardClick={handleCardSelection}
            selected={selectedCards[card.card_id]}
          />
        </Grid>
      ))}
    </Grid>
  );
  

  if (!user) {
    return (
      <div style={{ padding: '16px' }}>
        <LoginPrompt />
      </div>
    );
  }
  

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Fehler: {error}</div>;
  }

  const headerText = isAdminDeck 
    ? (editMode ? 'Admin-Deck bearbeiten' : 'Admin-Deck erstellen')
    : (editMode ? 'Deck bearbeiten' : 'Deck erstellen');


  return (
    <>
      <CustomizableHeader
        leftIcon={<DecksIcon />}
        leftText={headerText}
        rightContent={<CardCounterContent />}
      />
      <Box
        sx={{
          flexGrow: 1,
          p: theme.spacing(2),
          pb: theme.spacing(10),
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ActionButton
                action="save"
                onClick={() => setSaveDialogOpen(true)}
                sx={{
                  opacity: selectedCardCount === 20 ? 1 : 0.5,
                  pointerEvents: selectedCardCount === 20 ? 'auto' : 'none',
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                  marginRight: theme.spacing(1),
                }}
              >
                {editMode ? 'Aktualisieren' : 'Speichern'}
              </ActionButton>
              <ActionButton
                action="cancel"
                onClick={() => {isAdminDeck ? navigate('/admin/decks') : navigate('/user-decks')}}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  marginLeft: theme.spacing(1),
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ActionButton
              action="filter"
              onClick={handleFilterDialogOpen}
              sx={{
                width: '100%',
                p: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider
              sx={{
                backgroundColor: theme.palette.divider,
                height: '1px',
                width: '95%',
                margin: theme.spacing(2, 'auto'),
              }}
            />
          </Grid>
        </Grid>

        {renderCards(filteredCardData)}

        <CustomDialog
          open={saveDialogOpen}
          onClose={() => setSaveDialogOpen(false)}
          title="Deck Speichern"
        >
          <Box
            sx={{
              pt: theme.spacing(3),
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            <form onSubmit={handleFinalSave}>
              <CustomStyledTextField
                label="Deckname"
                placeholder="Deckname eingeben"
                value={deckName}
                onChange={(e) => setDeckName(e.target.value)}
                fullWidth
                required
              />
              <CustomStyledTextField
                label="Beschreibung"
                placeholder="Optionale Beschreibung hinzufügen"
                value={deckDescription}
                onChange={(e) => setDeckDescription(e.target.value)}
                multiline
                rows={3}
                fullWidth
              />
              <ActionButton
                action="save"
                type="submit"
                sx={{
                  mt: theme.spacing(2),
                  alignSelf: 'center',
                  width: 'fit-content',
                }}
              >
                Deck Speichern
              </ActionButton>
            </form>
          </Box>
        </CustomDialog>
      </Box>
    </>
  );
};

export default Deckbuilding;
