import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import InfoHint from '../common/InfoHint';

const LoginPrompt = () => (
  <InfoHint
    message="Dieser Service ist nur für angemeldete User verfügbar."
  >
    <Link to="/anmelden" style={{ textDecoration: 'none' }}>
      <Typography component="span" color="primary" sx={{ textDecoration: 'underline' }}>
        Anmelden
      </Typography>
    </Link>{' '}
    oder{' '}
    <Link to="/registrieren" style={{ textDecoration: 'none' }}>
      <Typography component="span" color="primary" sx={{ textDecoration: 'underline' }}>
        Registrieren
      </Typography>
    </Link>
  </InfoHint>
);

export default LoginPrompt;
