import React from 'react';
import { Box, Typography, Divider, Switch, useTheme } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AdminPanelSettings as AdminIcon } from '@mui/icons-material';
import CustomizableHeader from '../components/common/CustomizeableHeader';
import { useAuth } from '../contexts/AuthContext';
import { useThemeContext } from '../contexts/ThemeContext';
import ActionButton from '../components/common/ActionButton';
import { Link } from 'react-router-dom';

const Profil = () => {
  const { user, logout } = useAuth();
  const { mode, toggleMode } = useThemeContext();
  const theme = useTheme();

  return (
    <>
      <CustomizableHeader
        leftIcon={<AccountCircleIcon />}
        leftText="Profil"
        rightContent={
          user && user.is_admin ? (
            <Link to="/admin" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AdminIcon />
            </Link>
          ) : null
        }
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(3) }}>
        <Box sx={{ 
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          p: theme.spacing(2),
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}>
          <Typography variant="h5">Hi, {user ? user.username : 'Gast'}!</Typography>
          <Typography>Schön, dass du da bist.</Typography>

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Einstellungen</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
            <Typography>Dark Mode</Typography>
            <Switch checked={mode === 'dark'} onChange={toggleMode} />
          </Box>

          <Divider sx={{ my: theme.spacing(2) }} />

          {!user && (
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <ActionButton action="submit" color="primary" component={Link} to="/anmelden">
                Anmelden
              </ActionButton>
              <ActionButton component={Link} to="/registrieren">
                Registrieren
              </ActionButton>
            </Box>
          )}
          
          {user && (
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <ActionButton
                onClick={logout}
                color="error"
              >
                Abmelden
              </ActionButton>
            </Box>
          )}

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Nützliche Links</Typography>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: theme.spacing(1),
            mt: theme.spacing(1)
          }}>
            <Link to="/anleitung" style={{ 
              textDecoration: 'none', 
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              }
            }}>
              <Typography>Anleitung</Typography>
            </Link>
            <Link to="/ueber-uns" style={{ 
              textDecoration: 'none', 
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              }
            }}>
              <Typography>Über uns</Typography>
            </Link>
            <Link to="/datenschutz" style={{ 
              textDecoration: 'none', 
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              }
            }}>
              <Typography>Datenschutzerklärung</Typography>
            </Link>
            <Link to="/impressum" style={{ 
              textDecoration: 'none', 
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.primary.main,
              }
            }}>
              <Typography>Impressum</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profil;