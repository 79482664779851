import React, { useRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Paper } from "@mui/material";

const CardContainer = styled(Paper)(({ theme, selected, onClick }) => ({
  backgroundColor: `${theme.palette.primary.main}22`,
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  border: `2px solid ${selected ? theme.palette.success.main : theme.palette.divider}`,
  position: "relative",
  minHeight: "60px",
  margin: "8px",
  cursor: onClick ? "pointer" : "default",
  transition: "all 0.2s ease",
  boxShadow: selected ? `0 0 10px ${theme.palette.success.main}` : "none",
  outline: 'none',
 
  '&:focus': {
    outline: 'none'
  }
}));

const CardHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "12px",
  width: "100%",
});

const CardTitle = styled("div")({
  fontWeight: "bold",
  flexGrow: 1,
  minWidth: 0,
  overflow: "hidden",
  whiteSpace: "nowrap",
});

const BlockerBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: "0.5em 0.75em",
  borderRadius: "4px",
  fontSize: "1em",
  fontWeight: "bold",
  flexShrink: 0,
}));

const CardId = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(2),
  fontSize: "0.75rem",
  color: theme.palette.text.secondary,
}));

const FullCardItem = ({ card, handleCardClick, selected, showCardId = false }) => {
  const [fontSize, setFontSize] = useState(16);
  const titleRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const checkAndAdjustSize = () => {
      if (!titleRef.current || !containerRef.current) return;

      const title = titleRef.current;
      const container = containerRef.current;
      const containerWidth = container.offsetWidth - 80;
      title.style.fontSize = '16px';
      
      if (title.scrollWidth > containerWidth) {
        const ratio = containerWidth / title.scrollWidth;
        const newSize = Math.max(12, Math.floor(16 * ratio));
        setFontSize(newSize);
      } else {
        setFontSize(16);
      }
    };

    checkAndAdjustSize();
    window.addEventListener('resize', checkAndAdjustSize);
    
    return () => window.removeEventListener('resize', checkAndAdjustSize);
  }, [card.name]);

  return (
    <CardContainer
      ref={containerRef}
      onClick={handleCardClick ? (e) => handleCardClick(card.card_id, e) : undefined}
      selected={selected}
    >
      <CardHeader>
        <CardTitle ref={titleRef} style={{ fontSize: `${fontSize}px` }}>
          {card.card_name}
        </CardTitle>
        {card.blocker > 0 && <BlockerBadge>{card.blocker}</BlockerBadge>}
      </CardHeader>
      <Typography variant="subtitle2" color="textSecondary">
        {card.category.category_name}
      </Typography>
      <Box mt={2}>
        <Typography variant="body2">{card.effect}</Typography>
      </Box>
      {showCardId && <CardId>ID: {card.card_id}</CardId>}
    </CardContainer>
  );
};

export default FullCardItem;
