import { useApiClient } from './apiClient';

export const useCategoryService = () => {
  const apiClient = useApiClient();
  return {
    getCategories: () => apiClient.get('api/categories'),
    createCategory: (newCategory) => apiClient.post('api/categories', JSON.stringify({
      category_name: newCategory.name
    })),
    updateCategory: (id, categoryData) => {
      return apiClient.put(`api/categories/${id}`, JSON.stringify({
        category_name: categoryData.name
      }));
    },
    deleteCategory: (categoryId) => apiClient.delete(`api/categories/${categoryId}`)
  };
};