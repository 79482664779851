import React from "react";
import { Link } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PeopleIcon from '@mui/icons-material/People';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeckIcon from '@mui/icons-material/ViewModule';
import HistoryIcon from '@mui/icons-material/History';
import NewsIcon from '@mui/icons-material/Announcement';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CategoryIcon from '@mui/icons-material/Category';
import CustomizableHeader from "../../components/common/CustomizeableHeader";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.2rem',
  color: theme.palette.text.primary,
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}));

const AdminPortal = () => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomizableHeader
        leftIcon={<AdminPanelSettingsIcon />}
        leftText="Admin Portal"
      />
      <Box sx={{ flexGrow: 1, p:  theme.spacing(3) }}>
        <Box sx={{
          padding: 4,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: (theme) => theme.shape.borderRadius,
          backgroundColor: (theme) => theme.palette.background.paper,
          color: (theme) => theme.palette.text.primary,
        }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <StyledLink to="/admin/users">
                <PeopleIcon color="primary" sx={{ marginRight: '12px' }} /> Benutzer
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink to="/admin/karten">
                <CreditCardIcon color="primary" sx={{ marginRight: '12px' }} /> Karten
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink to="/admin/kategorien">
                <CategoryIcon color="primary" sx={{ marginRight: '12px' }} /> Kategorien
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink to="/admin/decks">
                <DeckIcon color="primary" sx={{ marginRight: '12px' }} /> Admin-Decks
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink to="/admin/spielhistorie">
                <HistoryIcon color="primary" sx={{ marginRight: '12px' }} /> Spielhistorie
              </StyledLink>
            </Grid>
            <Grid item>
              <StyledLink to="/admin/news">
                <NewsIcon color="primary" sx={{ marginRight: '12px' }} /> News
              </StyledLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminPortal;
