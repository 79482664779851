import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Divider,
  Link,
  useTheme,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useAuthService } from '../../services/authService';
import Logo from '../../components/common/Logo';
import Item from '../../components/common/Item';
import ActionButton from '../../components/common/ActionButton';
import { useMessage } from '../../contexts/MessageContext';

const LogIn = () => {
  const authService = useAuthService();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const theme = useTheme();
  const { showMessage } = useMessage();

  const handleGoHome = () => {
    navigate('/home');
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await authService.login(username, password);
      login(username, response.data.user.is_admin);
      showMessage('Erfolgreich angemeldet!', 'success');
      navigate('/home');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage('Benutzername oder Passwort falsch');
      } else {
        showMessage('Anmeldung fehlgeschlagen.', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        component={RouterLink}
        to="/home"
        sx={{ display: 'flex', alignItems: 'center', mb: 2, textDecoration: 'none', color: 'inherit' }}
      >
        <Logo src="/logo192.png" alt="App Logo" size={3} />
        <Typography variant="body-normal" sx={{ ml: 1 }}>
          Facettenschach
        </Typography>
      </Box>
      
      <Box sx={{ 
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          p: theme.spacing(2),
          m: theme.spacing(1),
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Anmelden
        </Typography>
        
        <form onSubmit={handleLogin}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="username"
            label="Benutzername"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputLabelProps={{ required: false }}
          />
          <TextField
            fullWidth
            margin="normal"
            required
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputLabelProps={{ required: false }}
          />
          
          <ActionButton
            type="submit"
            action="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 0 }}
            disabled={isLoading}
          >
            {isLoading ? 'Anmeldung...' : 'Anmelden'}
          </ActionButton>
        </form>

        {/* Display error message if present */}
        {errorMessage && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}

        <Divider sx={{ my: 2 }} />

        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          Noch kein Konto?{' '}
          <Link component={RouterLink} to="/registrieren" style={{ color: theme.palette.primary.main }}>
            Registrieren
          </Link>
        </Typography>
      </Box>
      <ActionButton
        color="primary"
        size="large"
        onClick={handleGoHome}
        sx={{
          display: 'block',
          mx: 'auto',
          mt: 2,
        }}
      >
        Zur Startseite
      </ActionButton>
    </Box>
  );
};

export default LogIn;
