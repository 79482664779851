import React from 'react';
import { Box, Typography, Divider, useTheme } from '@mui/material';
import Logo from '../../components/common/Logo';
import CustomizableHeader from '../../components/common/CustomizeableHeader';

const Imprint = () => {
  const theme = useTheme();

  return (
    <>
      <CustomizableHeader
        leftIcon={<Logo src="/logo192.png" alt="Logo" size={4}/>}
        leftText="Facettenschach"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(3) }}>
        <Box sx={{ 
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          p: theme.spacing(2),
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}>
          <Typography variant="h5">
            Impressum
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1) }}>
            Felix Gappel<br />
            Am Rasthof 2<br />
            01665 Klipphausen
          </Typography>
          
          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">
            Kontakt
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1) }}>
            E-Mail: chesscraft@web.de
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Imprint;