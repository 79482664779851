import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';
import DeckSelectionStep from './DeckSelectionStep';
import InfoHint from '../common/InfoHint';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../common/ActionButton';
import FullDeckItem from '../decks/FullDeckItem';

const GameStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDeckId, setSelectedDeckId] = useState(null);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  // Define steps based on user's authentication status
  const steps = user
    ? ['Deck wählen', 'Gegner finden', 'Spiel starten']
    : ['Deck wählen', 'Testen'];

  const handleNext = () => {
    if (activeStep === 0 && selectedDeckId) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleDeckSelect = (deckId, deck) => {
    setSelectedDeckId(deckId);
    setSelectedDeck(deck);
  };

  const handleStartTest = () => {
    if (selectedDeckId) {
      navigate(`/testen/${selectedDeckId}`);
    }
  };

  // Render content based on step and user status
  const getStepContent = (step) => {
    if (!user) {
      // Not logged-in steps
      switch (step) {
        case 0:
          return <DeckSelectionStep onSelect={handleDeckSelect} />;
        case 1:
          return (
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <ActionButton action="start" onClick={handleStartTest} >Test Starten</ActionButton>
              </Box>
            </Box>
          );
        default:
          return 'Unbekannter Schritt';
      }
    } else {
      // Logged-in steps
      switch (step) {
        case 0:
          return <DeckSelectionStep onSelect={handleDeckSelect} />;
        case 1:
          return (
            <Box sx={{ textAlign: 'center' }}>
              <InfoHint message="Gegner Matching und Live-Spielen werden noch implementiert. Sie können das Spiel jedoch testen." />
              <FullDeckItem
                deck={selectedDeck}
                selected={true}
                showStats={false}
              />

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <ActionButton action="start" onClick={handleStartTest}>Test Starten</ActionButton>
              </Box>
            </Box>
          );
        case 2:
          return (
            <Typography variant="h6" align="center">
              Bereit, das Spiel zu starten!
            </Typography>
          );
        default:
          return 'Unbekannter Schritt';
      }
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ mb: 2 }}>{getStepContent(activeStep)}</Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          {activeStep > 0 && (
            <ActionButton action="back" onClick={handleBack} />
          )}
          {activeStep < steps.length - 1 && (
            <ActionButton
              action="next"
              onClick={handleNext}
              disabled={(activeStep === 1 && user) || !selectedDeckId}
            >
              {activeStep === steps.length - 1 ? 'Spiel starten' : 'Weiter'}
            </ActionButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GameStepper;
