import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';

// Create the context with a default value
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(false);
  const [authService, setAuthService] = useState(null);

  // Authentication check method
  const checkAuthentication = async (authServiceInstance) => {
    if (!authServiceInstance) return;
    try {
      const response = await authServiceInstance.checkAuth();
      setUser(response.data.user);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    } catch (error) {
      setUser(null);
      localStorage.removeItem('user');
    } finally {
      setLoading(false);
    }
  };

  // Login method
  const login = (username, is_admin) => {
    const userData = { username, is_admin };
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  // Logout method
  const logout = async () => {
    if (authService) {
      await authService.logout();
    }
    setUser(null);
    localStorage.removeItem('user');
  };

  // Memoize the context value
  const contextValue = useMemo(() => ({
    user,
    login,
    logout,
    loading,
    setAuthService // Expose this to allow setting auth service
  }), [user, loading]);

  // Check authentication when auth service is available
  useEffect(() => {
    if (authService && !user) {
      checkAuthentication(authService);
    } else if (authService) {
      setLoading(false);
    }
  }, [authService]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook with null check
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};