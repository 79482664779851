import React from "react";
import { Box, Typography, Divider, Button, useTheme } from '@mui/material';
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';
import CustomizableHeader from "../../components/common/CustomizeableHeader";
import InfoHint from "../../components/common/InfoHint";
import DownloadIcon from '@mui/icons-material/Download';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';

const Anleitung = () => {
  const theme = useTheme();

  return (
    <>
      <CustomizableHeader
        leftIcon={<ArticleSharpIcon />}
        leftText="Anleitung"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(3) }}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            p: theme.spacing(2),
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          <Typography variant="h5">Video-Anleitung 🎥</Typography>
          <Box sx={{ my: theme.spacing(2) }}>
            <Link
              to="/external-link"
              state={{ url: 'https://youtube.com/your-tutorial-video' }}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                startIcon={<YouTubeIcon />}
                sx={{
                  textTransform: 'none',
                  bgcolor: '#FF0000',
                  '&:hover': {
                    bgcolor: '#CC0000',
                  },
                }}
              >
                Tutorial auf YouTube ansehen
              </Button>
            </Link>
          </Box>
          <InfoHint message="Eine detaillierte Video-Anleitung ist bald auf YouTube verfügbar!" />

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Interaktive Erklärung 🎮</Typography>
          <Typography align="left" sx={{ mb: theme.spacing(2) }}>
            Entdecke die Grundlagen von Facettenschach durch unsere interaktive Einführung. Lerne Schritt für Schritt die besonderen Regeln und Mechaniken kennen.
          </Typography>
          <InfoHint message="Interaktive Erklärungskomponenten werden bald implementiert." />

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5">Textuelle Anleitung 📖</Typography>
          <Box sx={{ my: theme.spacing(2) }}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              sx={{
                textTransform: 'none',
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                '&:hover': {
                  borderColor: theme.palette.primary.dark,
                  bgcolor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
              onClick={() => {
                // Add download logic here
                console.log('Downloading instructions...');
              }}
            >
              Anleitung herunterladen (PDF)
            </Button>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Eine umfassende schriftliche Anleitung mit allen Regeln, Strategien und Beispielen.
          </Typography>
          <InfoHint message="Die vollständige PDF-Anleitung wird in Kürze zum Download bereitgestellt." />
        </Box>
      </Box>
    </>
  );
};

export default Anleitung;