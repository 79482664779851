import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../components/common/ActionButton';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/home');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'text.primary',
        padding: 2,
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '6rem', fontWeight: 'bold' }}>
        404
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Seite nicht gefunden 😢
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Die angeforderte Seite existiert nicht oder wurde verschoben. 
        Kehren Sie zur Startseite zurück, um weiter zu navigieren.
      </Typography>
      <ActionButton
        color="primary"
        size="large"
        onClick={handleGoHome}
      >
        Zur Startseite
      </ActionButton>
    </Box>
  );
};

export default NotFound;
