import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import FullDeckItem from './FullDeckItem';

const DeckSelector = ({ decks, adminDecks, onSelect, user, showStats = true }) => {
  const [selectedDeckId, setSelectedDeckId] = useState(null);

  const handleDeckClick = (deckId, deckName) => {
    setSelectedDeckId(deckId);
    onSelect(deckId, deckName);
  };

  const renderDeck = (deck, isSelected) => (
    <Grid item xs={12} key={deck.id}>
      <FullDeckItem
        deck={deck}
        handleDeckClick={(id) => handleDeckClick(id, deck)}
        selected={isSelected}
        showStats={showStats}
      />
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      {user && decks.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Eigene Decks
          </Typography>
          <Grid container spacing={2}>
            {decks.map((deck) => renderDeck(deck, selectedDeckId === deck.deck_id))}
          </Grid>
        </>
      )}
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Vorgeschlagene Decks
      </Typography>
      <Grid container spacing={2}>
        {adminDecks.map((deck) => renderDeck(deck, selectedDeckId === deck.deck_id))}
      </Grid>
    </Box>
  );
};

export default DeckSelector;
