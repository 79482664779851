import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDeckService } from '../../services/deckService';
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  List,
  ListItem,
  Tabs,
  Tab,
  useTheme,
} from '@mui/material';
import FullCardItem from "../../components/cards/FullCardItem";
import FullDeckItem from "../../components/decks/FullDeckItem";
import ActionButton from "../../components/common/ActionButton";
import LoginPrompt from "../../components/decks/LoginPromt";
import { useAuth } from "../../contexts/AuthContext";
import InfoHint from "../../components/common/InfoHint";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import { useMessage } from "../../contexts/MessageContext";

const Decks = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const deckService = useDeckService();
  const { user } = useAuth();
  const { showMessage } = useMessage();

  const [decks, setDecks] = useState([]);
  const [adminDecks, setAdminDecks] = useState([]);
  const [selectedDeckIndex, setSelectedDeckIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isDeleteDeckConfirmationOpen, setIsDeleteDeckConfirmationOpen] = useState(false);

  useEffect(() => {
    fetchDecks();
    fetchAdminDecks();
  }, []);

  const fetchDecks = async () => {
    try {
      setLoading(true);
      const response = await deckService.getDecks();
      setDecks(response.data.decks.map(deck => ({
        ...deck,
        cards: deck.cards || [], // Ensure cards is always an array
      })));
    } catch (error) {
      showMessage("Fehler beim laden der Decks", 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchAdminDecks = async () => {
    try {
      const response = await deckService.getPublicDecks();
      setAdminDecks(response.data.decks.map(deck => ({
        ...deck,
        cards: deck.cards || [], // Ensure cards is always an array
      })));
    } catch (error) {
      showMessage("Fehler beim laden der vorgeschlagenen Decks.", 'error');
    }
  };

  const handleSelectAdminDeck = async (deck) => {
    try {
      const cardIds = (deck.cards || []).map((card) => card.card_id);
      await deckService.createDeck(cardIds, deck.deck_name);
      await fetchDecks();
      showMessage("Deck erfolgreich erstellt.", 'success');
    } catch (error) {
      showMessage("Fehler beim erstellen eines Decks.", 'error');
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedDeckIndex(newValue);
  };

  const handleCreateNewDeck = () => {
    navigate('/deck-bauen');
  };

  const handleEditDeck = () => {
    navigate('/deck-bauen', { state: { deck: decks[selectedDeckIndex] } });
  };

  const handleDeleteDeck = () => {
    setIsDeleteDeckConfirmationOpen(true);
  };

  const confirmDeleteDeck = async () => {
    setIsDeleteDeckConfirmationOpen(false);
    try {
      await deckService.deleteDeck(decks[selectedDeckIndex].deck_id);
      await fetchDecks();
      showMessage('Deck erfolgreich gelöscht.', 'success');
    } catch (error) {
      showMessage("Fehler beim löschen des Decks.", 'error');
    }
  };

  const cancelDeleteDeck = () => {
    setIsDeleteDeckConfirmationOpen(false);
  };

  const calculateStats = (cards) => {
    if (!cards || cards.length === 0) return { avgBlocker: 0, dominantCategory: 'N/A' };

    const totalBlocker = cards.reduce((sum, card) => sum + card.blocker, 0);
    const avgBlocker = (totalBlocker / cards.length).toFixed(2);

    const categoryCount = cards.reduce((acc, card) => {
      acc[card.category.category_name] = (acc[card.category.category_name] || 0) + 1;
      return acc;
    }, {});

    const dominantCategory = Object.entries(categoryCount).reduce((a, b) => (a[1] > b[1] ? a : b))[0];

    return { avgBlocker, dominantCategory };
  };

  const renderDeckContent = (deck) => (
    <>
      <FullDeckItem
        deck={{
          ...deck,
          averageBlocker: parseFloat(calculateStats(deck.cards).avgBlocker),
          dominantCategory: calculateStats(deck.cards).dominantCategory,
        }}
        selected={false}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          mb: theme.spacing(2),
        }}
      >
        <ActionButton action="edit" onClick={handleEditDeck} />
        <ActionButton action="delete" onClick={handleDeleteDeck} />
      </Box>
      <Grid container spacing={2}>
        {deck.cards && deck.cards.map((card, index) => (
          <Grid item xs={12} key={index}>
            <FullCardItem card={card} />
          </Grid>
        ))}
      </Grid>
    </>
  );

  const renderAdminDeckItem = (deck) => (
    <ListItem key={deck.id} sx={{ display: 'block', mb: 2 }}>
      <FullDeckItem
        deck={{
          ...deck,
          averageBlocker: parseFloat(calculateStats(deck.cards).avgBlocker),
          dominantCategory: calculateStats(deck.cards).dominantCategory,
        }}
        handleDeckClick={() => handleSelectAdminDeck(deck)}
      />
    </ListItem>
  );

  if (!user) {
    return (
      <div style={{ padding: '16px' }}>
        <LoginPrompt />
      </div>
    );
  }
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const selectedDeck = decks[selectedDeckIndex] || null;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: theme.zIndex.appBar,
        }}
      >
        <Tabs
          value={selectedDeckIndex}
          onChange={handleTabChange}
          aria-label="deck selection tabs"
          sx={{ mb: theme.spacing(2) }}
          variant="fullWidth"
        >
          <Tab label="Deck 1" sx={{ textTransform: 'none' }} />
          <Tab label="Deck 2" sx={{ textTransform: 'none' }} />
          <Tab label="Deck 3" sx={{ textTransform: 'none' }} />
          <Tab label="Deck 4" sx={{ textTransform: 'none' }} />
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          m: 2,
          pb: theme.spacing(8),
          pt: theme.spacing(6),
        }}
      >
        {selectedDeck ? (
          renderDeckContent(selectedDeck)
        ) : (
          <Box sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
            <InfoHint message="Dieser Deckplatz ist frei" />
            <ActionButton
              action="create"
              onClick={handleCreateNewDeck}
            >
              Neues Deck erstellen
            </ActionButton>
            <Typography variant="h5" sx={{ pt: 7, mb: 2 }}>
              Vorgeschlagene Decks:
            </Typography>
            <List>
              {adminDecks.map(renderAdminDeckItem)}
            </List>
          </Box>
        )}
      </Box>
      <ConfirmationModal 
        open={isDeleteDeckConfirmationOpen}
        onClose={cancelDeleteDeck}
        onConfirm={confirmDeleteDeck}
        title="Deck Löschen?"
        message="Dieses Deck wird unwiederbringlich gelöscht. Möchtest du wirklich löschen?"
        confirmText="Ja, löschen"
        cancelText="Abbrechen"
      />
    </>
  );
};

export default Decks;
