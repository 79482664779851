import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Science as TestIcon } from '@mui/icons-material';
import { useDeckService } from '../../services/deckService';
import {
  Grid,
  Box,
  Modal,
  Backdrop,
  Typography,
  useTheme,
} from '@mui/material';

import CustomizableHeader from "../../components/common/CustomizeableHeader";
import ActionButton from "../../components/common/ActionButton";
import FullCardItem from "../../components/cards/FullCardItem";
import AvailableCardsModal from "../../components/playing/AvailableCardsModal";
import ConfirmationModal from "../../components/common/ConfirmationModal";

const Testen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { deckId: routeDeckId } = useParams();
  const deckId = routeDeckId;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [displayedCards, setDisplayedCards] = useState([]);
  const [availableCards, setAvailableCards] = useState([]);
  const [remainingCards, setRemainingCards] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalLocked, setIsModalLocked] = useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [isAvailableCardsModalOpen, setIsAvailableCardsModalOpen] = useState(false);
  const [rerollCount, setRerollCount] = useState(3);
  const [allCardsUsed, setAllCardsUsed] = useState(false);
  const [isEndTestConfirmationOpen, setIsEndTestConfirmationOpen] = useState(false);

  const deckService = useDeckService();

  useEffect(() => {
    if (deckId) {
      loadDeck(deckId);
    }
  }, [deckId]);

  const loadDeck = async (deckId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await deckService.getDeck(deckId);
      initializeDeck(response.data.deck.cards);
    } catch (userDeckError) {
      setError(userDeckError.response?.data?.message || userDeckError.message);
    } finally {
      setLoading(false);
    }
  };

  const initializeDeck = (deckCards) => {
    const shuffledCards = deckCards.sort(() => 0.5 - Math.random());
    setDisplayedCards(shuffledCards.slice(0, 3));
    setAvailableCards(shuffledCards.slice(3));
    setRemainingCards(shuffledCards.length - 3);
    setAllCardsUsed(false);
  };

  const handleEndTesten = () => {
    setIsEndTestConfirmationOpen(true);
  };

  const confirmEndTesten = () => {
    setIsEndTestConfirmationOpen(false);
    navigate('/spielen');
  };

  const cancelEndTesten = () => {
    setIsEndTestConfirmationOpen(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setIsModalLocked(false);
    setIsCardModalOpen(true);
  };

  const handleUseCard = () => {
    setIsModalLocked(true);
  };

  const handleActionClick = () => {
    if (selectedCard && selectedCard.blocker > 1) {
      selectedCard.blocker -= 1;
      setSelectedCard({ ...selectedCard });
    } else {
      setDisplayedCards((prevCards) =>
        prevCards.filter((c) => c.card_id !== selectedCard.card_id)
      );

      if (availableCards.length > 0) {
        const newCard = availableCards[0];
        setDisplayedCards((prevCards) => [...prevCards, newCard]);
        setAvailableCards((prevCards) => prevCards.slice(1));
        setRemainingCards((prev) => prev - 1);
      } else {
        // All cards used
        if (displayedCards.length === 1) {
          setAllCardsUsed(true);
        }
      }

      setIsCardModalOpen(false);
      setSelectedCard(null);
    }
  };

  const handleCancelClick = () => {
    setIsCardModalOpen(false);
    setSelectedCard(null);
  };

  const handleRerollUse = () => {
    if (rerollCount <= 0 || allCardsUsed) {
      return;
    }

    if (availableCards.length < 3) {
      return;
    }

    const newDisplayedCards = [];
    const updatedAvailableCards = [...availableCards];

    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * updatedAvailableCards.length);
      newDisplayedCards.push(updatedAvailableCards[randomIndex]);
      updatedAvailableCards.splice(randomIndex, 1);
    }

    setDisplayedCards(newDisplayedCards);
    setAvailableCards(updatedAvailableCards);
    setRemainingCards(updatedAvailableCards.length);
    setRerollCount(rerollCount - 1);
  };

  return (
    <>
      <CustomizableHeader
        leftIcon={<TestIcon />}
        leftText="Testen"
        rightContent={
          <ActionButton action="cancel" onClick={handleEndTesten}>
            Testen beenden
          </ActionButton>
        }
      />
      <ConfirmationModal 
        open={isEndTestConfirmationOpen}
        onClose={cancelEndTesten}
        onConfirm={confirmEndTesten}
        title="Test beenden?"
        message="Der aktuelle Testfortschritt geht verloren. Möchtest du wirklich den Test beenden?"
        confirmText="Ja, beenden"
        cancelText="Abbrechen"
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(2) }}>
        {allCardsUsed ? (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="textSecondary">
                Alle Karten wurden verwendet. Der Test ist abgeschlossen.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" spacing={2}>
            {displayedCards.map((card) => (
              <Grid item xs={12} key={card.card_id}>
                <FullCardItem
                  card={card}
                  handleCardClick={() => handleCardClick(card)}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <ActionButton
                onClick={handleRerollUse}
                disabled={rerollCount <= 0 || allCardsUsed}
                fullWidth
              >
                Neue Karten ({rerollCount} übrig)
              </ActionButton>
            </Grid>
            <Grid item xs={12} >
              <ActionButton
                onClick={() => setIsAvailableCardsModalOpen(true)}
                disabled={allCardsUsed || remainingCards === 0}
                fullWidth
              >
                Verfügbare Karten: {remainingCards}
              </ActionButton>
            </Grid>
          </Grid>
        )}
      </Box>

      <Modal
        open={isCardModalOpen}
        onClose={!isModalLocked ? handleCancelClick : undefined}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: {
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            maxWidth: '400px',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: theme.shape.borderRadius,
            p: theme.spacing(1),
            boxShadow: theme.shadows[5],
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          {selectedCard && (
            <>
              <FullCardItem card={selectedCard} />
              <Box
                mt={2}
                sx={{ display: 'flex', justifyContent: 'space-around' }}
              >
                {!isModalLocked ? (
                  <>
                    <ActionButton onClick={handleCancelClick}>
                      Abbrechen
                    </ActionButton>
                    <ActionButton onClick={handleUseCard}>
                      Karte einsetzen
                    </ActionButton>
                  </>
                ) : (
                  <ActionButton onClick={handleActionClick}>
                    Zug machen
                  </ActionButton>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <AvailableCardsModal
        open={isAvailableCardsModalOpen}
        onClose={() => setIsAvailableCardsModalOpen(false)}
        availableCards={availableCards}
      />
    </>
  );
};

export default Testen;
