import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import NewsIcon from "@mui/icons-material/Announcement";
import { Link } from "react-router-dom";
import { useNewsService } from "./../../services/newsService";
import CustomStyledTextField from "../../components/common/CustomStyledTextField";
import ActionButton from "../../components/common/ActionButton";
import NewsContent from "../../components/common/NewsContent";
import CustomizableHeader from "../../components/common/CustomizeableHeader";
import { useTheme } from "@mui/material/styles";
import { useMessage } from "../../contexts/MessageContext";
import ConfirmationModal from "../../components/common/ConfirmationModal";

const AdminNews = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [news, setNews] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isDeleteNewsConfirmationOpen, setIsDeleteNewsConfirmationOpen] = useState(false);
  const [newsToDeleteId, setNewsToDeleteId] = useState(null);


  const newsService = useNewsService();
  const theme = useTheme();
  const { showMessage } = useMessage();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await newsService.getNews();
      setNews(response.data.news);
    } catch (error) {
      showMessage("Fehler beim laden der News.", "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await newsService.createNews({ title, content });
      setTitle("");
      setContent("");
      setShowForm(false);
      fetchNews();
      showMessage("News erfolgreich erstellt.", "success");
    } catch (error) {
      showMessage("Fehler beim erstellen von News.", "error");
    }
  };

  const handleDeleteNews = (id) => {
    setNewsToDeleteId(id);
    setIsDeleteNewsConfirmationOpen(true);
  };
  

  const confirmDeleteNews = async () => {
    setIsDeleteNewsConfirmationOpen(false);
    if (!newsToDeleteId) return;
    try {
      await newsService.deleteNews(newsToDeleteId);
      setNewsToDeleteId(null);
      fetchNews();
      showMessage("News erfolgreich gelöscht.", "success");
    } catch (error) {
      showMessage("Fehler beim Löschen", "error");
    }
  };
  

  const cancelDeleteNews = () => {
    setIsDeleteNewsConfirmationOpen(false);
  };

  return (
    <>
      <CustomizableHeader
        leftIcon={<NewsIcon />}
        leftText="News"
        rightContent={
          <Link to="/admin">
            <IconButton>
              <AdminPanelSettingsIcon />
            </IconButton>
          </Link>
        }
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(3) }}>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Box
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
                p: theme.spacing(2),
                bgcolor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            >
              <ActionButton
                fullWidth
                action={showForm ? "cancel" : "create"}
                onClick={() => setShowForm(!showForm)}
              />
              {showForm && (
                <Box mt={2}>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(2),
                      alignItems: "center",
                    }}
                  >
                    <CustomStyledTextField
                      label="Titel"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                      fullWidth
                    />
                    <CustomStyledTextField
                      label="Inhalt"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      required
                      fullWidth
                      multiline
                      rows={2}
                    />
                    <ActionButton fullWidth action="save" type="submit">
                      Veröffentlichen
                    </ActionButton>
                  </form>
                </Box>
              )}
              <Divider sx={{ my: theme.spacing(2) }} />  
              <NewsContent
                news={news}
                showDeleteButton={true}
                onDelete={(id) => handleDeleteNews(id)}
              />
            </Box>
          </Grid>
        </Grid>
        <ConfirmationModal 
          open={isDeleteNewsConfirmationOpen}
          onClose={cancelDeleteNews}
          onConfirm={confirmDeleteNews}
          title="News löschen?"
          message="Die News wird unwiederbringlich gelöscht. Möchtest du wirklich löschen?"
          confirmText="Ja, löschen"
          cancelText="Abbrechen"
      />
      </Box>
    </>
  );
};

export default AdminNews;
