import { useApiClient } from './apiClient';

export const useNewsService = () => {
  const apiClient = useApiClient();

  return {
    getNews: () => apiClient.get('api/news'),
    createNews: (newsPost) => apiClient.post('api/news', JSON.stringify(newsPost)),
    deleteNews: (news_id) => apiClient.delete(`api/news/${news_id}`),
  };
};