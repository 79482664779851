import React from "react";
import HistoryIcon from '@mui/icons-material/History';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomizableHeader from "../../components/common/CustomizeableHeader";
import InfoHint from "../../components/common/InfoHint";

const AdminGameHistory = () => {
    return (
        <>
          <CustomizableHeader
            leftIcon={<HistoryIcon />}
            leftText="Spielhistorie"
            rightContent={
                <Link to="/admin">
                    <IconButton>
                        <AdminPanelSettingsIcon />
                    </IconButton>
                </Link>
            }
          />
          <Box sx={{ flexGrow: 1, padding: 3 }}>
            <InfoHint message="Hier sollen in Zukunft die gespielten Spiele einsehbar sein." />
          </Box>
        </>
      );
    };

export default AdminGameHistory;