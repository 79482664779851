import React, { useState, useEffect, useCallback} from 'react';
import { useCardService } from '../../services/cardService';
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Divider,
  MenuItem
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  AdminPanelSettings as AdminIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';
import ActionButton from '../../components/common/ActionButton';
import { Link } from 'react-router-dom';
import CustomizableHeader from '../../components/common/CustomizeableHeader';
import CustomStyledTextField from '../../components/common/CustomStyledTextField';
import { useTheme } from '@mui/material/styles';
import FullCardItem from '../../components/cards/FullCardItem';
import { useMessage } from '../../contexts/MessageContext';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { useCategoryService } from '../../services/categoryService';

const AdminCards = () => {
  const cardService = useCardService();
  const categoryService = useCategoryService();
  const [categories, setCategories] = useState([]);
  const [cards, setCards] = useState([]);
  const [newCard, setNewCard] = useState({
    card_name: '',
    blocker: '',
    category_id: '',
    effect: '',
  });
  const [editingCard, setEditingCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const itemsPerPage = 20;

  const { showMessage } = useMessage();
  const theme = useTheme();

  useEffect(() => {
    fetchCards();
    fetchCategories();
  }, []);

  const fetchCards = async () => {
    setLoading(true);
    try {
      const response = await cardService.getCards();
      setCards(response.data.cards);
      console.log(cards);
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };
  

  const fetchCategories = async () => {
    try {
      const response = await categoryService.getCategories();
      setCategories(response.data.categories);
    } catch (err) {
      showMessage(err.message, 'error');
    }
  };

  const paginatedCards = cards.slice(
    currentPage * itemsPerPage, 
    (currentPage + 1) * itemsPerPage
  );

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < cards.length) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const createCard = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const cardPayload = {
        ...newCard,
        category_id: parseInt(newCard.category_id, 10),
      };
      await cardService.createCard(cardPayload);
      await fetchCards();
      setNewCard({ card_name: '', blocker: '', category_id: '', effect: '' });
      setShowNewCardForm(false);
      showMessage('Karte erfolgreich erstellt');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };
  

  const updateCard = async () => {
    if (!editingCard) return;
    setLoading(true);
    try {
      // Prepare a payload with only the allowed fields
      const updatePayload = {
        card_name: editingCard.card_name,
        blocker: editingCard.blocker,
        category_id: parseInt(editingCard.category_id),
        effect: editingCard.effect
      };
  
      await cardService.updateCard(editingCard.card_id, updatePayload);
      await fetchCards();
      setEditingCard(null);
      showMessage('Karte erfolgreich aktualisiert');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (id) => {
    setCardToDelete(id);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCardToDelete(null);
  };
  
  const deleteCard = async () => {
    if (!cardToDelete) return;
    setLoading(true);
    try {
      await cardService.deleteCard(cardToDelete);
      setCards((prevCards) => prevCards.filter((card) => card.card_id !== cardToDelete));
      showMessage('Karte erfolgreich gelöscht');
    } catch (err) {
      showMessage(err.message, 'error');
    } finally {
      setLoading(false);
      setIsDeleteModalOpen(false);
      setCardToDelete(null);
    }
  };
  

  const handleEditClick = (card) => {
    setEditingCard(card);
  };
  

  const handleCancelEdit = () => {
    setEditingCard(null);
  };

  const handleEditChange = useCallback((e, field) => {
    setEditingCard((prev) => ({
      ...prev,
      [field]: field === 'blocker' 
        ? Math.max(0, parseInt(e.target.value) || 0)
        : e.target.value,
    }));
  }, []);

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <CustomizableHeader
        leftIcon={<CreditCardIcon />}
        leftText="Karten"
        rightContent={
          <Link to="/admin">
            <IconButton>
              <AdminIcon />
            </IconButton>
          </Link>
        }
      />
      <Box
        sx={{
          flexGrow: 1,
          padding: 2,
          border: 1,
          borderColor: 'divider',
          borderRadius: 2,
          bgcolor: 'background.paper',
          boxShadow: 1,
          margin: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12}>
            <ActionButton
              sx={{
                mt: theme.spacing(3)
              }}
              fullWidth
              action={showNewCardForm ? 'cancel' : 'create'}
              onClick={() => setShowNewCardForm(!showNewCardForm)}
            >
              {showNewCardForm ? 'Abbrechen' : 'Neue Karte erstellen'}
            </ActionButton>
            {showNewCardForm && (
              <Box mt={2}>
                <form
                  onSubmit={createCard}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <CustomStyledTextField
                    value={newCard.card_name}
                    onChange={(e) =>
                      setNewCard({ ...newCard, card_name: e.target.value })
                    }
                    label="Name"
                    required
                  />
                  <CustomStyledTextField
                    type="number"
                    value={newCard.blocker}
                    onChange={(e) =>
                      setNewCard({
                        ...newCard,
                        blocker: Math.max(0, parseInt(e.target.value)),
                      })
                    }
                    label="Blocker"
                    required
                  />
                  <CustomStyledTextField
                    select
                    value={newCard.category_id}
                    onChange={(e) =>
                      setNewCard({ ...newCard, category_id: e.target.value })
                    }
                    label="Kategorie"
                    required
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.category_id} value={category.category_id}>
                        {category.category_name}
                      </MenuItem>
                    ))}
                  </CustomStyledTextField>


                  <CustomStyledTextField
                    value={newCard.effect}
                    onChange={(e) =>
                      setNewCard({ ...newCard, effect: e.target.value })
                    }
                    label="Effekt"
                    required
                  />
                  <ActionButton
                    action="save"
                    type="submit"
                    variant="create"
                  >
                    Speichern
                  </ActionButton>
                </form>
              </Box>
            )}
          </Grid>
          <Grid xs={12}>
            {cards.length === 0 ? (
              <Typography>Keine Karten gefunden :/</Typography>
            ) : (
              paginatedCards.map((card) => (
                <Box key={card.card_id} sx={{ marginBottom: 2 }}>
                  <Divider sx={{ my: 2 }} />
                  {editingCard && editingCard.card_id === card.card_id ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        border: `1px solid ${theme.palette.divider}`,
                        padding: 2,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      <CustomStyledTextField
                        value={editingCard.card_name}
                        onChange={(e) => handleEditChange(e, 'card_name')}
                        label="Name"
                      />
                      <CustomStyledTextField
                        type="number"
                        value={editingCard.blocker}
                        onChange={(e) => handleEditChange(e, 'blocker')}
                        label="Blocker"
                      />
                      <CustomStyledTextField
                        select
                        value={editingCard.category_id}
                        onChange={(e) => handleEditChange(e, 'category_id')}
                        label="Kategorie"
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.category_id} value={category.category_id}>
                            {category.category_name}
                          </MenuItem>
                        ))}
                      </CustomStyledTextField>
                      <CustomStyledTextField
                        value={editingCard.effect}
                        onChange={(e) => handleEditChange(e, 'effect')}
                        label="Effekt"
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <ActionButton action="save" onClick={updateCard} />
                        <ActionButton
                          action="cancel"
                          onClick={handleCancelEdit}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box >
                      <FullCardItem
                        card={card}
                        handleCardClick={() => handleEditClick(card)}
                        showCardId={true}
                      />
                      <Box 
                        sx={{ 
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, auto)',
                          justifyContent: 'center',
                          gap: 1
                        }}
                      >
                        <ActionButton
                          action="edit"
                          onClick={() => handleEditClick(card)}
                        />
                        <ActionButton
                          action="delete"
                          onClick={() => openDeleteModal(card.card_id)}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ))
            )}
            <Box
              sx={{
                position: 'fixed',
                top: 40,
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                zIndex: 1000,
                backgroundColor: 'background.paper',
                padding: 1,
                borderRadius: 2,
                boxShadow: 2
              }}
            >
              <ActionButton 
                action="back" 
                onClick={handlePreviousPage} 
                disabled={currentPage === 0}
              />
              
              <Typography variant="body2">
                {currentPage + 1}/{Math.ceil(cards.length / itemsPerPage)}
              </Typography>
              
              <ActionButton 
                action="next" 
                onClick={handleNextPage} 
                disabled={(currentPage + 1) * itemsPerPage >= cards.length}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={deleteCard}
        title="Karte löschen?"
        message="Diese Aktion kann nicht rückgängig gemacht werden. Möchtest du die Karte wirklich löschen?"
        confirmText="Ja, löschen"
        cancelText="Abbrechen"
      />
    </Box>
  );
};

export default AdminCards;
