import React from 'react';
import { Box, Typography, Divider, useTheme } from '@mui/material';
import CustomizableHeader from '../../components/common/CustomizeableHeader';
import Logo from '../../components/common/Logo';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  const theme = useTheme();
  
  return (
    <>
      <CustomizableHeader
        leftIcon={<Logo src="/logo192.png" alt="Logo" size={4}/>}
        leftText="Facettenschach"
        rightContent={null}
      />
      <Box sx={{ flexGrow: 1, p: theme.spacing(3) }}>
        <Box sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          p: theme.spacing(2),
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}>
          <Typography variant="h5">
            Datenschutzerklärung
          </Typography>

          {/* Inhaltsverzeichnis */}
          <Box sx={{ mt: theme.spacing(2), mb: theme.spacing(4), pl: theme.spacing(2) }}>
            <Typography variant="body1" component="div">
              <Box component="a" href="#section1" sx={{ 
                display: 'block', 
                color: theme.palette.text.primary,
                textDecoration: 'underline',
                mb: theme.spacing(1)
              }}>
                1. Datenschutz auf einen Blick
              </Box>
              <Box component="a" href="#section2" sx={{ 
                display: 'block', 
                color: theme.palette.text.primary,
                textDecoration: 'underline',
                mb: theme.spacing(1)
              }}>
                2. Hosting
              </Box>
              <Box component="a" href="#section3" sx={{ 
                display: 'block', 
                color: theme.palette.text.primary,
                textDecoration: 'underline',
                mb: theme.spacing(1)
              }}>
                3. Allgemeine Hinweise und Pflichtinformationen
              </Box>
              <Box component="a" href="#section4" sx={{ 
                display: 'block', 
                color: theme.palette.text.primary,
                textDecoration: 'underline',
                mb: theme.spacing(1)
              }}>
                4. Datenerfassung auf dieser Website
              </Box>
            </Typography>
          </Box>

          <Divider sx={{ my: theme.spacing(2) }} />

          <Typography variant="h5" id="section1" sx={{ mt: theme.spacing(3) }}>
            1. Datenschutz auf einen Blick
          </Typography>
          
          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Allgemeine Hinweise
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(3), mb: theme.spacing(2) }}>
            Datenerfassung auf dieser Website
          </Typography>

          <Typography variant="sectionHeading" sx={{ mt: theme.spacing(2), fontWeight: 'bold' }}>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1), mb: theme.spacing(2) }}>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            <Box 
              component="a" 
              href="#Verantwortliche-Stelle" 
              sx={{ color: theme.palette.text.primary, textDecoration: 'underline', ml: theme.spacing(0.5) }}
            >
              Hinweis zur Verantwortlichen Stelle
            </Box> 
            in dieser Datenschutzerklärung entnehmen.
          </Typography>


          <Typography variant="sectionHeading" sx={{ mt: theme.spacing(2), fontWeight: 'bold' }}>
            Wie erfassen wir Ihre Daten?
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1), mb: theme.spacing(2) }}>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Formular eingeben.
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
          </Typography>

          <Typography variant="sectionHeading" sx={{ mt: theme.spacing(2), fontWeight: 'bold' }}>
            Wofür nutzen wir Ihre Daten?
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1), mb: theme.spacing(2) }}>
            Daten werden erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten sowie unseren Services.
          </Typography>

          <Typography variant="sectionHeading" sx={{ mt: theme.spacing(2), fontWeight: 'bold' }}>
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1), mb: theme.spacing(2) }}>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </Typography>

          <Typography variant="h5" id="section2" sx={{ mt: theme.spacing(3) }}>
            2. Hosting
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Unsere Website wird bei einem externen Anbieter gehostet. Personenbezogene Daten, die über diese Website erfasst werden, wie z. B. IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertrags- und Kontaktdaten sowie Websitezugriffe, werden auf den Servern des Hosters gespeichert.
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Das Hosting erfolgt zur Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO) und im berechtigten Interesse an einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine Einwilligung eingeholt wurde, erfolgt die Verarbeitung zusätzlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG; diese Einwilligung ist jederzeit widerrufbar.
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Unser Hoster verarbeitet Ihre Daten nur im Rahmen der Leistungserbringung und gemäß unseren Weisungen.
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Wir nutzen den folgenden Hoster:  
            <br /><br />
            <strong>HOSTINGER operations, UAB</strong><br />
            Švitrigailos str. 34<br />
            Vilnius 03230 Lithuania
          </Typography>

          <Typography variant="h5" id="section3" sx={{ mt: theme.spacing(3) }}>
            3. Allgemeine Hinweise und Pflichtinformationen
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Datenschutz
          </Typography>
          <Typography variant="body1">
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
          </Typography>

          <Typography variant="h6" id="Verantwortliche-Stelle" sx={{ mt: theme.spacing(2) }}>
            Hinweis zur verantwortlichen Stelle
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1) }}>
            <strong>Felix Gappel</strong><br />
            Am Rasthof 2<br />
            01665 Klipphausen
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(1) }}>
            E-Mail: chesscraft@web.de
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Speicherdauer
          </Typography>
          <Typography variant="body1">
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website
          </Typography>
          <Typography variant="body1">
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </Typography>
          <Typography variant="body1">
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung
          </Typography>
          <Typography variant="body1" sx={{ mb: theme.spacing(1) }}>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </Typography>
          <Typography variant='body1'>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO)
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </Typography>
          <Typography variant="body1">
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Recht auf Datenübertragbarkeit
          </Typography>
          <Typography variant="body1">
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Auskunft, Berichtigung und Löschung
          </Typography>
          <Typography variant="body1">
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Recht auf Einschränkung der Verarbeitung
          </Typography>
          <Typography variant="body1">
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            SSL- bzw. TLS-Verschlüsselung
          </Typography>
          <Typography variant="body1">
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
          </Typography>

          <Typography variant="h5" id="section4" sx={{ mt: theme.spacing(3) }}>
            4. Datenerfassung auf dieser Website
          </Typography>

          <Typography variant="h6" sx={{ mt: theme.spacing(2) }}>
            Cookies
          </Typography>
          <Typography variant="body1">
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Wir setzen ausschließlich technisch notwendige First-Party-Cookies und verzichten auf die Verwendung von Third-Party-Cookies, die von Drittunternehmen stammen.
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Cookies haben verschiedene Funktionen. Technisch notwendige Cookies sind unerlässlich, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden. Wir verwenden keine Cookies zu Werbezwecken oder zur Analyse des Nutzerverhaltens.
          </Typography>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Folgende Cookies werden von uns verwendet:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Permanent Mode</strong> Speichert die Einstellung für das gewählte Design (Dark- oder Light-Mode), um die Benutzererfahrung zu optimieren.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Permanent User:</strong> Dient dazu festzustellen, ob ein Nutzer eingeloggt ist, und unterstützt die Bereitstellung gewünschter Funktionen.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>JWT Access Token & Refresh:</strong> Diese Cookies sorgen für Sicherheit, indem sie die Authentifizierung und autorisierte Zugriffe ermöglichen.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>CSRF Access Token & Refresh:</strong> Diese Cookies schützen vor Cross-Site-Request-Forgery-Angriffen und gewährleisten eine sichere Nutzung der Webseite.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" sx={{ mt: theme.spacing(2) }}>
            Diese Cookies werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, da wir ein berechtigtes Interesse an der technisch fehlerfreien und sicheren Bereitstellung unserer Dienste haben. Sofern eine Einwilligung zur Speicherung von Cookies oder vergleichbaren Technologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDG); die Einwilligung ist jederzeit widerrufbar.
          </Typography>


          <Divider sx={{ my: theme.spacing(2) }} />
          
          <Typography variant="body1">
            Quelle:
            <Link to="/external-link" state={{ url: 'https://www.e-recht24.de' }}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                }}
              >
                https://www.e-recht24.de
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;