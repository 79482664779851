import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Paper } from "@mui/material";
import { LibraryBooks as DeckIcon } from '@mui/icons-material';

const DeckContainer = styled(Paper)(({ theme, selected, onClick }) => ({
  backgroundColor: `${theme.palette.secondary.main}11`,
  padding: theme.spacing(3),
  color: theme.palette.text.primary,
  border: `2px solid ${selected ? theme.palette.success.main : theme.palette.divider}`,
  borderRadius: theme.spacing(2),
  position: "relative",
  minHeight: "120px",
  margin: "8px",
  cursor: onClick ? "pointer" : "default",
  transition: "all 0.3s ease",
  boxShadow: selected 
    ? `0 0 15px ${theme.palette.success.main}`
    : `0 4px 6px ${theme.palette.divider}`,
  outline: 'none',
  
  '&:hover': {
    transform: onClick ? 'translateY(-4px)' : 'none',
    boxShadow: onClick 
      ? `0 6px 12px ${theme.palette.divider}` 
      : `0 4px 6px ${theme.palette.divider}`,
  },

  '&:focus': {
    outline: 'none'
  }
}));

const DeckHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  width: "100%",
  marginBottom: "12px",
});

const DeckTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.25rem",
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StatBadge = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: "0.4em 0.75em",
  borderRadius: "12px",
  fontSize: "0.9em",
  fontWeight: "500",
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  marginTop: theme.spacing(1),
}));

const DeckDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.9rem',
  marginTop: theme.spacing(1),
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}));

const FullDeckItem = ({ deck, handleDeckClick, selected, showStats = true }) => {
    // Safely handle undefined or non-number averageBlocker
    const averageBlocker = typeof deck.averageBlocker === 'number' ? deck.averageBlocker.toFixed(1) : 'N/A';
  
    return (
      <DeckContainer
        onClick={handleDeckClick ? () => handleDeckClick(deck.deck_id) : undefined}
        selected={selected}
      >
        <DeckHeader>
          <DeckTitle>
            <DeckIcon sx={{ fontSize: '1.25rem' }} />
            {deck.deck_name}
          </DeckTitle>
        </DeckHeader>
  
        {showStats && (
          <StatsContainer>
            <StatBadge>
              Ø Blocker: {averageBlocker}
            </StatBadge>
            <StatBadge>
              {deck.dominantCategory || 'No Category'}
            </StatBadge>
          </StatsContainer>
        )}
  
        <DeckDescription>
          {deck.description || 'Keine Beschreibung verfügbar'}
        </DeckDescription>
      </DeckContainer>
    );
  };

export default FullDeckItem;