import React from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import { CancelSharp as CancelSharpIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  showCloseIcon = true,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          maxWidth: '400px',
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          p: theme.spacing(3),
          boxShadow: theme.shadows[5],
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        {/* Title and Close Icon */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: theme.spacing(2),
          }}
        >
          <Typography variant="h6">{title}</Typography>
          {showCloseIcon && (
            <IconButton
              onClick={onClose}
              sx={{ color: theme.palette.text.secondary }}
              aria-label="close"
            >
              <CancelSharpIcon />
            </IconButton>
          )}
        </Box>

        {/* Dialog Content */}
        <Box>{children}</Box>
      </Box>
    </Modal>
  );
};

// PropTypes for better clarity and validation
CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showCloseIcon: PropTypes.bool,
};

// Default Props
CustomDialog.defaultProps = {
  onConfirm: () => {},
  showCloseIcon: true,
};

export default CustomDialog;
