import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDeckService } from "../../services/deckService";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ActionButton from "../../components/common/ActionButton";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DeckIcon from '@mui/icons-material/ViewModule';
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import CustomizableHeader from "../../components/common/CustomizeableHeader";
import FullDeckItem from "../../components/decks/FullDeckItem";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import { useTheme } from '@mui/material/styles';
import { useMessage } from "../../contexts/MessageContext";

const AdminDeckManager = () => {
  const [decks, setDecks] = useState([]);
  const [loading, setLoading] = useState(true);
  const deckService = useDeckService();
  const navigate = useNavigate();
  const theme = useTheme();
  const { showMessage } = useMessage();
  const [deckToDelete, setDeckToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


  useEffect(() => {
    fetchDecks();
  }, []);

  const fetchDecks = async () => {
    try {
      const response = await deckService.getPublicDecks();
      setDecks(response.data.decks);
    } catch (error) {
      showMessage("Fehler beim Laden der öffentlichen Decks.", "error");
    } finally {
      setLoading(false);
    }
  };
  

  const handleEditDeck = (deck) => {
    navigate('/deck-bauen', { state: { editDeck: deck, isAdmin: true } });
  };

  const handleCreateDeckClick = () => {
    navigate('/deck-bauen', { state: { isAdmin: true } });
  };

  const openDeleteModal = (deckId) => {
    setDeckToDelete(deckId);
    setIsDeleteModalOpen(true);
  };
  
  const closeDeleteModal = () => {
    setDeckToDelete(null);
    setIsDeleteModalOpen(false);
  };
  
  const confirmDeleteDeck = async () => {
    if (!deckToDelete) return;
    setLoading(true);
    try {
      await deckService.deleteAdminDeck(deckToDelete);
      await fetchDecks();
      showMessage("Deck erfolgreich gelöscht.", "success");
    } catch (error) {
      showMessage("Fehler beim Löschen des Decks.", "error");
    } finally {
      setLoading(false);
      closeDeleteModal();
    }
  };
  



  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizableHeader
        leftIcon={<DeckIcon />}
        leftText="Admin-Decks"
        rightContent={
          <Link to="/admin" style={{ color: 'inherit' }}>
            <IconButton>
              <AdminIcon />
            </IconButton>
          </Link>
        }
      />
      <Box sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        p: theme.spacing(3),
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        m: theme.spacing(2),
        boxShadow: theme.shadows[1],
      }}>
        <Box sx={{ marginBottom: theme.spacing(3) }}>
          <ActionButton
            fullWidth
            action="create"
            onClick={handleCreateDeckClick}
          >
            Neues Deck erstellen
          </ActionButton>
        </Box>

        {decks.map((deck) => (
          <React.Fragment key={deck.id}>
            <Divider sx={{ my: theme.spacing(2) }} />
            <FullDeckItem
              deck={deck}
              showStats={false}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                mb: theme.spacing(2),
              }}
            >
              <ActionButton action="edit" onClick={() => handleEditDeck(deck)} />
              <ActionButton action="delete" onClick={() => openDeleteModal(deck.id)} />
            </Box>
          </React.Fragment>
        ))}
      </Box>
      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDeleteDeck}
        title="Deck löschen?"
        message="Möchten Sie dieses Deck wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
        confirmText="Ja, löschen"
        cancelText="Abbrechen"
      />
    </>
  );
};

export default AdminDeckManager;
