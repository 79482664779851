import React, { createContext, useState, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Create context
const MessageContext = createContext();

// Message Provider Component
export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  const showMessage = (text, severity = 'success') => {
    setMessage({ text, severity });
    
    // Automatically clear message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };

  const handleClose = () => {
    setMessage(null);
  };

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {children}
      {message && (
        <Snackbar
          open={!!message}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleClose}
            severity={message.severity}
            sx={{ width: '100%' }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      )}
    </MessageContext.Provider>
  );
};

// Custom hook for using messages
export const useMessage = () => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context;
};