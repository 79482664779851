import React from 'react';
import {
  Modal,
  Box,
  Typography,
  useTheme
} from '@mui/material';
import ActionButton from "../../components/common/ActionButton";

const ConfirmationModal = ({ 
  open, 
  onClose, 
  onConfirm, 
  title = "Bist du sicher?", 
  message = "Diese Aktion kann nicht rückgängig gemacht werden. Möchtest du fortfahren?",
  confirmText = "Ja, fortfahren",
  cancelText = "Abbrechen"
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          maxWidth: '400px',
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          p: theme.spacing(3),
          boxShadow: theme.shadows[5],
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          {message}
        </Typography>
        
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-around', 
            gap: theme.spacing(2) 
          }}
        >
          <ActionButton 
            action="cancel"
            onClick={onClose} 
          >
            {cancelText}
          </ActionButton>
          <ActionButton
            action="next"
            onClick={onConfirm} 
          >
            {confirmText}
          </ActionButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;