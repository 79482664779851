import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export const useApiClient = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://pybay.de',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  let isRefreshing = false;
  let refreshSubscribers = [];

  const onRefreshed = (token) => {
    refreshSubscribers.forEach((callback) => callback(token));
    refreshSubscribers = [];
  };

  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  // Request interceptor to set CSRF tokens
  api.interceptors.request.use((config) => {
    let csrfToken;
    if (config.url?.endsWith('/auth/refresh')) {
      csrfToken = getCookie('csrf_refresh_token');
    } else {
      csrfToken = getCookie('csrf_access_token');
    }
    if (csrfToken) {
      config.headers['X-CSRF-TOKEN'] = csrfToken;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Response interceptor to handle token refreshing and logout
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      
      // Skip token refresh if the error is from login or refresh endpoints
      if (originalRequest.url?.endsWith('/auth/login') || originalRequest.url?.endsWith('/auth/refresh')) {
        return Promise.reject(error);
      }
  
      // If error is 401 and refresh is allowed, attempt refresh
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        if (originalRequest.url.endsWith('/auth/refresh')) {
          // Refresh token is invalid or expired, trigger logout
          window.dispatchEvent(new Event('logout'));
          return Promise.reject(error);
        }
  
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const refreshResponse = await api.post('/auth/refresh');
            isRefreshing = false;
            onRefreshed(refreshResponse.data.accessToken);
            
            // Retry original request with new token
            return api(originalRequest);
          } catch (refreshError) {
            isRefreshing = false;
            // Logout the user and navigate to login if refresh fails
            await logout();
            navigate('/anmelden');
            return Promise.reject(refreshError);
          }
        }
  
        // Queue request if refresh is already in progress
        return new Promise((resolve, reject) => {
          addRefreshSubscriber((newToken) => {
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            resolve(api(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    }
  );

  return api;
};

// Utility function to get cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}